import { ConfigInterface } from "../interfaces/config.interface";
import cfg from "../../webservice-config.json";

export const useConfig = (): ConfigInterface => {
    const pages = Object.keys(cfg.webservice.pages).map((key) => {
        // @ts-ignore
        const page = cfg.webservice.pages[key];
        return {
            path: key,
            id: page.id,
            title: page.title,
            widgets: page.widgets,
        };
    });

    const config = Object.assign({}, cfg.webservice);
    // Yliajetaan sivuobjekti listalla
    // @ts-ignore
    config.pages = pages;

    return (config as unknown) as ConfigInterface;
};
