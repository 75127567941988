enum CustomerType {
    PERSON,
    ORGANIZATION,
}

export enum CartCustomerType {
    MAIN = "",
    DELIVERY = "delivery_",
    BILLING = "billing_",
}

export interface CustomerInterface {
    first_name?: string;
    last_name?: string;
    phone?: string;
    email?: string;
    organization?: boolean;
    vat_number?: string;
    customer_type?: CustomerType;
    marketing_authorization?: boolean;
    address_line1?: string;
    address_line2?: string;
    postal_code?: string;
    city?: string;
    country?: string;
    company_name?: string;
    marketing_permission?: boolean;
}
