import { v4 as uuid } from "uuid";
import { CartActionType } from "../../interfaces/cart-action-type.enum";
import { DeliveryMethodInterface } from "../../interfaces/delivery-method.interface";
import { CartInterface } from "../../interfaces/cart.interface";
import { CartStep } from "../../interfaces/cart-step";
import { Reducer } from "redux";

const initState: CartInterface = {
    uuid: uuid(),
    items: [],
    codes_used: [],
    totalEur: 0.0,
    cart_error: null,
    step: CartStep.CUSTOMER_DETAILS,
    customer: {},
    delivery_methods: [],
    delivery_customer: {},
    checkout: null,
    created: false,
    available_delivery_methods: [],
    isLoading: true,
    isLoadingDeliveryMethods: true,
    isLoadingCheckout: false,
    isLoadingCartItem: false,
    token: "",
};

export interface CartReducerActionInterface {
    type: CartActionType;
    campaign_code?: any;
    message?: string;
    exception_parameters?: {
        [key: string]: any
    };
    step?: CartStep;
    uuid?: string;
    checkout?: any;
    cart?: any;
    value?: boolean;
    available_delivery_methods?: DeliveryMethodInterface[];
}

const cartReducer: Reducer<CartInterface> = (
    state = initState,
    action: CartReducerActionInterface,
) => {
    switch (action.type) {
        case CartActionType.SET_LOADING:
            return {
                ...state,
                isLoading: action.value,
            };
        case CartActionType.SET_DELIVERY_LOADING:
            return {
                ...state,
                isLoadingDeliveryMethods: action.value,
            };
        case CartActionType.SET_CHECKOUT_LOADING: {
            return {
                ...state,
                isLoadingCheckout: action.value,
            };
        }
        case CartActionType.SET_CART_ITEM_LOADING: {
            return {
                ...state,
                isLoadingCartItem: action.value,
            };
        }
        case CartActionType.ADD_AVAILABLE_DELIVERY_METHODS:
            return {
                ...state,
                available_delivery_methods: action.available_delivery_methods,
                isLoading: false,
                isLoadingDeliveryMethods: false,
            };
        case CartActionType.CART_CREATED:
            return { ...state, created: action.value, cart_error: null };
        case CartActionType.CART_ERROR:
            return {
                ...state, cart_error: action.message,
                isLoading: false,
                isLoadingDeliveryMethods: false,
                isLoadingCartItem: false,
                exception_parameters: action.exception_parameters,
            };
        case CartActionType.CLEAR_CART_ERROR:
            return {
                ...state,
                cart_error: null, isLoading: false, isLoadingDeliveryMethods: false, isLoadingCartItem: false,
                exception_parameters: null,
            };
        case CartActionType.CLEAR_CHECKOUT_ERROR:
            return {
                ...state,
                checkout_error: null,
                isLoadingCheckout: false,
                exception_parameters: null,
            };
        case CartActionType.CHECKOUT_CREATED:
            return {
                ...state,
                checkout: action.checkout,
                cart_error: null,
            };
        case CartActionType.CHECKOUT_ERROR:
            return {
                ...state,
                checkout_error: action.message,
                isLoadingCheckout: false,
            };
        case CartActionType.APPLY_CART: {
            return {
                ...state,
                ...action.cart,
                cart_error: null,
                isLoading: false,
            };
        }
        case CartActionType.CLEAR_CART:
            return {
                ...state,
                items: [],
                cart_error: null,
            };
        case CartActionType.RESET_CART:
            return {
                ...initState,
            };
        case CartActionType.ADD_CART_UUID:
            return { ...state, uuid: action.uuid, cart_error: null };
        case CartActionType.CART_STEP:
            return { ...state, step: action.step, cart_error: null };
        default:
            return state;
    }
};

export default cartReducer;
