import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import wsConfig from "../webservice-config.json";

i18n
    .use(ICU)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    lng: wsConfig.webservice.default_language || "fi",
    resources: {
        fi: {
            translations: require("../src/translations/fi.json"),
        },
        en: {
            translations: require("../src/translations/en.json"),
        },
    },
    fallbackLng: "fi",
    debug: process.env.NODE_ENV === "development",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ",",
    },
    react: {
        useSuspense: true,
    },
});
export default i18n;
