import axios from "axios";
import { ProductInterface } from "interfaces/product.interface";
import { ProductActionType } from "interfaces/product-action-type.enum";

/**
 * Lataa tuotteet WS-API:sta
 */
export const loadProducts = () => async (dispatch: any) => {
    const result = await axios(
        `${process.env.GATSBY_WS_API_URL}/v1/${process.env.GATSBY_WS_ID}/products.json`
    );

    try {
        let products = result.data.products.map((product: ProductInterface) => {
            if (!product.availability) {
                product.availability = {
                    available: true,
                    max: 20,
                    min: 1,
                    step: 1,
                    status: "green",
                    info: "Hyvin tilaa",
                };
            }

            product.auto_slug = product.title.fi
                .toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^\w-]+/g, "");
            return product;
        });

        dispatch({ type: ProductActionType.ADD_PRODUCTS, products });
    } catch (e) {
        dispatch({ type: ProductActionType.PRODUCTS_LOADING_ERROR });
    }
};
