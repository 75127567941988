export enum ErrorCodes {
    UNSPECIFIED_EXCEPTION = "unspecified_exception",

    CART_CREATION_FAILED = "cart_creation_failed",
    CART_LOADING_FAILED = "cart_loading_failed",
    CART_NOT_FOUND = "cart_not_found",
    CART_ALREADY_EXISTS = "cart_already_exists",
    CART_TOKEN_MISMATCH = "cart_token_mismatch",

    CART_ITEMS_EMPTY = "cart_items_empty",
    CART_ITEM_NOT_FOUND = "cart_item_not_found",
    CART_ITEM_PRODUCT_NOT_FOUND = "cart_item_product_not_found",
    CART_ITEM_PRODUCT_MISMATCH = "cart_item_update_product_mismatch",
    CART_ITEM_AVAILABILITY_GENERAL = "cart_item_availability_general",
    CART_ITEM_AVAILABILITY_MIN = "cart_item_availability_min",
    CART_ITEM_AVAILABILITY_MAX = "cart_item_availability_max",
    CART_ITEM_AVAILABILITY_STEP = "cart_item_availability_step",
    CART_ITEM_AVAILABILITY_PRODUCT_QUOTA_REACHED = "cart_item_availability_product_quota_reached",

    SEAT_RESERVATIONS_NOT_ENOUGH_SEATS_AVAILABLE = "seat_reservations_not_enough_seats_available",
    SEAT_RESERVATION_NOT_OWNED = "seat_reservation_not_owned",
    NO_AVAILABLE_SEAT_GROUPS = "no_available_seat_groups",

    DELIVERY_METHOD_NOT_FOUND = "delivery_method_not_found",
    DELIVERY_METHOD_FORBIDDEN_BY_CART_ITEM = "delivery_method_forbidden_by_cart_item",

    CODE_NOT_FOUND = "code_not_found",
    CODE_ALREADY_IN_CART = "code_already_in_cart",
    CODE_MAX_COUNT_PER_ORDER = "code_max_count_per_order",
    NO_AVAILABLE_SERIAL_CARD_SLOTS = "no_available_serial_card_slots",
    SERIAL_CARD_EXPIRED = 'serial_card_expired',
    NO_MATCHING_SERIAL_CARD = 'no_matching_serial_card',
}
