import { ProductInterface } from "../../interfaces/product.interface";
import { ProductActionType } from "../../interfaces/product-action-type.enum";

export interface ProductReducerInterface {
    products: ProductInterface[];
    isLoaded: boolean;
    isEmpty: boolean;
}

const initState = {
    products: [],
    isLoaded: true,
    isEmpty: false,
};

const productReducer: any = (
    state = initState,
    action: { type: ProductActionType; products: ProductInterface[] }
) => {
    switch (action.type) {
        case ProductActionType.ADD_PRODUCTS:
            return {
                ...state,
                products: [...state.products, ...action.products],
                isLoaded: true,
                isEmpty: false,
            };
        case ProductActionType.PRODUCTS_LOADING_ERROR:
            return {
                isLoaded: true,
                isEmpty: true,
            };
        default:
            return state;
    }
};

export default productReducer;
