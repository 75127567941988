import { TranslatableStringInterface } from "./translatable-string.interface";
import { ProductInterface, ProductType } from "./product.interface";

export enum DeliveryGroup {
    TICKETS = "tickets",
    MERCHANDISE = "merchandise",
}

export interface DeliveryMethodInterface {
    id: string;
    title: TranslatableStringInterface;
    description?: TranslatableStringInterface;
    delivery_groups: DeliveryGroup[];
    available_for_product_types: ProductType[];
    product?: ProductInterface & { price_token: string | undefined };
    hidden: boolean;
}
