import React from "react";
import {Helmet} from "react-helmet";
import {useConfig} from "../hooks/use-config.hook";

const Loader: React.FC<React.PropsWithChildren> = (props) => {
    const {children} = props;
    const config = useConfig();

    return (
        <>
            <Helmet>
                <style>
                    {`   
                        html, body, #___gatsby, #gatsby-focus-wrapper {
                            min-height: 100%;
                            height: 100%;
                        }
                        
                        #gatsby-focus-wrapper > * {
                            min-height: 100%;
                        }
                `}
                </style>
                {config.metas && config.metas.description && (
                    <meta
                        name="description"
                        content={config.metas.description.fi}
                    />
                )}
            </Helmet>
            {children}
        </>
    );
};
export default Loader;
