import { StreamReducerInterface } from "../../interfaces/stream-reducer.interface.";
import { StreamTicketInterface } from "../../interfaces/stream-ticket.interface";

export enum StreamActionType {
    ADD_STREAMS = "ADD_STREAMS",
    UNKNOWN_TICKET_CODE = "UNKNOWN_TICKET_CODE",
    VIEW_VALIDATION_FAILED = "VIEW_VALIDATION_FAILED",
    UPDATE_VIEWER_ID = "UPDATE_VIEWER_ID",
}

const initState: StreamReducerInterface = {
    ticket: undefined,
    ticket_code: "",
    view_validation_failed: false,
    isLoaded: false,
    isEmpty: false,
};

interface StreamReducerActionInterface {
    type: StreamActionType;
    ticket_code: string;
    ticket: StreamTicketInterface;
    viewer_id: string;
}

const streamReducer: any = (
    state = initState,
    action: StreamReducerActionInterface
) => {
    switch (action.type) {
        case StreamActionType.ADD_STREAMS:
            return {
                ...state,
                ticket: action.ticket,
                ticket_code: action.ticket_code,
                view_validation_failed: false,
                isLoaded: true,
                isEmpty: false,
            };
        case StreamActionType.UNKNOWN_TICKET_CODE:
            return {
                ...state,
                view_validation_failed: false,
                isLoaded: true,
                isEmpty: true,
            };
        case StreamActionType.VIEW_VALIDATION_FAILED:
            return {
                ...state,
                ticket: undefined,
                ticket_code: undefined,
                view_validation_failed: true,
                isLoaded: false,
                isEmpty: false,
            };
        case StreamActionType.UPDATE_VIEWER_ID:
            return {
                ...state,
                ticket: {
                    ...state.ticket,
                    viewer: {
                        ...state.ticket?.viewer,
                        id: action.viewer_id,
                    },
                },
                view_validation_failed: false,
                isLoaded: true,
                isEmpty: false,
            };
        default:
            return state;
    }
};

export default streamReducer;
