import { VirtualCardActionType } from "../../interfaces/virtual-card-action-type.enum";
import { BadgeInterface } from "../../interfaces/badge.interface";
import { CustomerMembershipCardInterface } from "../../interfaces/customer-membership-card.interface";

export interface VirtualCardReducerInterface {
    customer_membership_card: CustomerMembershipCardInterface | null;
    badge: BadgeInterface | null;
    isLoaded: boolean;
    isEmpty: boolean;
    requires_authentication: boolean;
    authentication_failed: boolean;
    error_message: string | null;
}

const initState: VirtualCardReducerInterface = {
    customer_membership_card: null,
    badge: null,
    isLoaded: false,
    isEmpty: false,
    requires_authentication: true,
    authentication_failed: false,
    error_message: null,
};

interface VirtualCardReducerActionInterface {
    type: VirtualCardActionType;
    customer_membership_card: CustomerMembershipCardInterface | null;
    badge: BadgeInterface | null;
    error_message: string | null;
}

const virtualCardReducer: any = (
    state = initState,
    action: VirtualCardReducerActionInterface
) => {
    switch (action.type) {
        case VirtualCardActionType.CARD_CHECK_SUCCESS:
            return {
                customer_membership_card: action.customer_membership_card,
                requires_authentication: false,
                authentication_failed: false,
                isLoaded: true,
                isEmpty: false,
                error_message: null,
            };
        case VirtualCardActionType.BADGE_CHECK_SUCCESS:
            return {
                badge: action.badge,
                isLoaded: true,
                isEmpty: false,
                error_message: null,
            };
        case VirtualCardActionType.CARD_REQUIRES_AUTHENTICATION:
            return {
                ...state,
                isLoaded: false,
                isEmpty: true,
                requires_authentication: true,
                error_message: false,
            };
        case VirtualCardActionType.CARD_WRONG_PIN:
            return {
                ...state,
                isLoaded: false,
                isEmpty: true,
                authentication_failed: true,
            };
        case VirtualCardActionType.CARD_CHECK_ERROR:
        case VirtualCardActionType.BADGE_CHECK_ERROR:
            return {
                error_message: action.error_message,
                isLoaded: true,
                isEmpty: true,
            };
        default:
            return state;
    }
};

export default virtualCardReducer;
