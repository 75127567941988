export interface LiveReducerInterface {
    freeze_live: boolean;
    error: string | null;
    isLoaded: boolean;
    isEmpty: boolean;
}

export enum LiveReducerActionType {
    FREEZE_LIVE = "FREEZE_LIVE",
}

const initState: LiveReducerInterface = {
    freeze_live: false,
    error: null,
    isLoaded: true,
    isEmpty: false,
};

const liveReducer: any = (
    state = initState,
    action: { type: LiveReducerActionType; freeze_live: boolean }
) => {
    switch (action.type) {
        case LiveReducerActionType.FREEZE_LIVE:
            return {
                freeze_live: action.freeze_live,
                isLoaded: true,
                isEmpty: false,
                error: null,
            };
        default:
            return state;
    }
};

export default liveReducer;
