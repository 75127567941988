import { combineReducers } from "redux";
import cartReducer from "./cart.reducer";
import eventReducer, { EventReducerInterface } from "./event.reducer";
import virtualCardReducer, {
    VirtualCardReducerInterface,
} from "../reducers/virtual-card.reducer";
import productReducer, { ProductReducerInterface } from "./product.reducer";
import paymentReducer, { PaymentReducerInterface } from "./payment.reducer";
import liveReducer, { LiveReducerInterface } from "../reducers/live.reducer";
import streamReducer from "./stream.reducer";
import { StreamReducerInterface } from "../../interfaces/stream-reducer.interface.";
import { CartInterface } from "../../interfaces/cart.interface";

export default combineReducers<AppState>({
    cart: cartReducer,
    event: eventReducer,
    virtual_card: virtualCardReducer,
    product: productReducer,
    payment: paymentReducer,
    live: liveReducer,
    stream: streamReducer,
});

export interface AppState {
    cart: CartInterface;
    product: ProductReducerInterface;
    event: EventReducerInterface;
    payment: PaymentReducerInterface;
    live: LiveReducerInterface;
    virtual_card: VirtualCardReducerInterface;
    stream: StreamReducerInterface;
}
