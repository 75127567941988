import { EventActionType } from "../../interfaces/event-action-type.enum";
import { EventInterface } from "../../interfaces/event.interface";
import eventsJson from "../../../events.json";

export interface EventReducerInterface {
    events: EventInterface[];
    error: string | null;
    isLoaded: boolean;
    isEmpty: boolean;
}
const initState: EventReducerInterface = {
    // @ts-ignore
    events: (eventsJson.events as EventInterface[]) || [],
    error: null,
    isLoaded: false,
    isEmpty: false,
};

// Lähde cart.reducer -- muokattu vastaamaan haluttua dataa (last visited click fix) (ch1311)
const addOrReplace = (array: EventInterface[], event: EventInterface) => {
    const i = array.findIndex((_event) => _event.id === event.id);
    if (i > -1) array[i] = event;
    else array.push(event);
};

const eventReducer: any = (
    state = initState,
    action: {
        type: EventActionType;
        events: EventInterface[];
        event: EventInterface;
    }
) => {
    switch (action.type) {
        case EventActionType.ADD_EVENTS:
            return {
                events: action.events,
                isLoaded: true,
                isEmpty: false,
                error: null,
            };
        case EventActionType.UPDATE_EVENT:
            const newEvents = state.events;
            addOrReplace(newEvents, action.event);
            return {
                events: newEvents,
                isLoaded: true,
                isEmpty: false,
                error: null,
            };
        case EventActionType.EVENT_LOADING_ERROR: {
            return {
                events: action.events,
                isLoaded: true,
                isEmpty: true,
            };
        }
        default:
            return state;
    }
};

export default eventReducer;
