import { TranslatableStringInterface } from "../../interfaces/translatable-string.interface";
import { PaymentMethodInterface } from "../../interfaces/payment-method.interface";
import { PaymentActionType } from "../../interfaces/payment-action-type.enum";

export enum PaymentStatus {
    SELECTION_PENDING = "selection_pending",
    PAYMENT_PENDING = "payment_pending",
    PAID = "paid",
    CANCELLED = "cancelled",
}

export interface PaymentReducerInterface {
    status: PaymentStatus;
    expires: Date;
    account?: {
        id: string;
        name: TranslatableStringInterface;
    };
    payment_options: PaymentMethodInterface[];
    _links: {
        next_step?: string;
        payment_selection?: string;
        cancel?: string;
    };
}

const initState: PaymentReducerInterface = {
    status: PaymentStatus.SELECTION_PENDING,
    expires: new Date(),
    account: undefined,
    payment_options: [],
    _links: {
        next_step: "/xxxx",
        payment_selection: "/xxxxx",
        cancel: "/xxxxx",
    },
};

interface PaymentAction {
    type: PaymentActionType;
    config: PaymentReducerInterface;
    status?: PaymentStatus;
    err?: any;
}

const paymentReducer: any = (state = initState, action: PaymentAction) => {
    switch (action.type) {
        case PaymentActionType.APPLY_PAYMENT_CONFIG:
            return {
                ...action.config,
                errorMessage: null,
            };
        case PaymentActionType.SET_PAYMENT_STATUS: {
            return {
                ...state,
                status: action.status,
                errorMessage: null,
            };
        }
        default:
            return state;
    }
};

export default paymentReducer;
