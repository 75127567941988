export const poll = <T>(fn: any, timeout: any, interval: any): Promise<T> => {
    let endTime = Number(new Date()) + (timeout || 2000);
    interval = interval || 100;

    let checkCondition = (resolve: any, reject: any) => {
        const ajax = fn();
        ajax.then((response: any) => {
            console.log(response);
            if (response.data.status === 500) {
                reject(new Error("Tuntematon virhe tapahtui!"));
                return;
            }
            if (response.data.cart_checkout?.checkout_status === "ERROR") {
                reject(
                    new Error(
                        response.data.cart_checkout?.error_message?.fi ||
                            "Tuntematon virhetilanne."
                    )
                );
                return;
            }
            if (response.data.cart_checkout?.checkout_status === "COMPLETED") {
                resolve(response.data);
                return;
            }

            if (Number(new Date()) < endTime) {
                setTimeout(checkCondition, interval, resolve, reject);
            } else {
                reject(new Error("Virhe tapahtui käsitellessä tilaustasi!"));
            }
        });
    };
    return new Promise(checkCondition);
};
