export enum CartActionType {
    CART_CREATED = "CART_CREATED",
    CART_ERROR = "CART_ERROR",
    CHECKOUT_CREATED = "CHECKOUT_CREATED",
    CHECKOUT_ERROR = "CHECKOUT_ERROR",
    APPLY_CART = "APPLY_CART",
    CLEAR_CART = "CLEAR_CART",
    RESET_CART = "RESET_CART",
    ADD_CART_UUID = "ADD_CART_UUID",
    CART_STEP = "CART_STEP",
    CLEAR_CART_ERROR = "CLEAR_CART_ERROR",
    CLEAR_CHECKOUT_ERROR = "CLEAR_CHECKOUT_ERROR",
    ADD_AVAILABLE_DELIVERY_METHODS = "ADD_AVAILABLE_DELIVERY_METHODS",
    SET_LOADING = "SET_LOADING",
    SET_DELIVERY_LOADING = "SET_DELIVERY_LOADING",
    SET_CHECKOUT_LOADING = "SET_CHECKOUT_LOADING",
    SET_CART_ITEM_LOADING = "SET_CART_ITEM_LOADING",
}
