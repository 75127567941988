/**
 * Dekoraattori {@link CartService}n metodeille, joka varmentaa ostoskorin olemassaolon ennen kuin metodin muu osuus ajetaan.
 * @param target
 * @param key
 * @param descriptor
 * @constructor
 */
export function RequiresCartExistence(target: any, key: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function(...args: any[]) {
        // @ts-ignore koska kenttiä ei ole tyypitetty
        if(this.cartId === "" || this.cartId === undefined || this.cartToken === "" || this.cartToken === undefined) {
            // @ts-ignore koska kenttiä ei ole tyypitetty
            await this.createCart()
        }
        return originalMethod.apply(this, args);
    };

    return descriptor;
}
